<template>
  <AppPageCard>
    <template #card-title> Document </template>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";

export default {
  name: "CompanyDocumentPage",
  components: { AppPageCard },
};
</script>

<style scoped></style>
